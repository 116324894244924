import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { PATH } from '../../../common/path/puth';
import exit from '../../../assets/svg/exit.svg';
import profile from '../../../assets/svg/profile.svg';
import style from './HeaderMain.module.scss';
import CustomizedMenus from '../mainResults/menu/Menu';
import { MainLogo } from '../../sales/common/components/mainLogo/MainLogo';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { activeDraws, setCountry, setPage } from '../../../bll/reducers/mainFinishReducer';

type HeaderMainPropsType = {
  result?: boolean;
  main?: boolean;
};

export const HeaderMain = ({ result, main }: HeaderMainPropsType) => {
  const dispatch = useAppDispatch();
  const activeRaffle = useAppSelector(state => state.mainFinishDraws.draws.active);

  useEffect(() => {
    dispatch(setCountry({ country: '' }));
    dispatch(setPage({ page: 1 }));
  }, []);

  const selectStyleHandler = (isActive: boolean): string => {
    if (main) {
      return style.underlineFalse;
    } else {
      return isActive ? style.underlineTrue : style.underlineFalse;
    }
  };

  const navigate = useNavigate();

  const onRegistration = () => {
    navigate(`${PATH.customerBaseUrl}${PATH.registration}`);
  };
  const onLogin = () => {
    navigate(`${PATH.customerBaseUrl}${PATH.login}`);
  };
  const onDraws = () => {
    navigate(PATH.mainDraws);
  };
  const onResults = () => {
    navigate(PATH.mainFinishDraws);
  };

  const headerBg = result ? `${style.headerContainer} ${style.headerContainerBg}` : style.headerContainer;

  return (
    <header className={headerBg}>
      <MainLogo toggleLogoWidth={768} navigateLink={PATH.main} />
      <nav className={style.items}>
        <section className={style.info}>
          <p className={style.infoText} onClick={onDraws}>
            {activeRaffle.length > 0 && (
              <NavLink to={PATH.mainDraws} className={({ isActive }) => selectStyleHandler(isActive)}>
                Розыгрыши
              </NavLink>
            )}
          </p>

          <p className={style.infoText} onClick={onResults}>
            <NavLink to={PATH.mainFinishDraws} className={({ isActive }) => selectStyleHandler(isActive)}>
              Результаты
            </NavLink>
          </p>
        </section>
        <section className={style.shownMenu}>
          <div className={style.registrationContainer} onClick={onRegistration}>
            <img src={profile} alt='' />
            <span className={style.registration}>Регистрация</span>
          </div>
          <div className={style.exitContainer} onClick={onLogin}>
            <img src={exit} alt='' />
            <span className={style.exit}>Вход</span>
          </div>
        </section>
        <CustomizedMenus
          onLogin={onLogin}
          onRegistration={onRegistration}
          onDraws={onDraws}
          onResults={onResults}
          title={'Вход'}
        />
      </nav>
    </header>
  );
};
